import { useEffect, useState } from "react";
import apiHelper from "../../Commen/ApiHelper";
import { useNavigate } from "react-router-dom";
import Path from "../../Commen/Path";
import Loader from "../../Components/Loader";
import { Alert, Snackbar } from "@mui/material";

export default function RegisterScreen({ Auth, setAuth }) {
  const navigate = useNavigate()
  const [Message, setMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [userDetails, setuserDetails] = useState({
    fullName: "",
    phone: '',
    password: "",
    city: "",
    role: 2
  })

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });


  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };



  const [City, setCity] = useState([])
  const [selectedCity, setselectedCity] = useState("")

  async function getCity() {
    try {
      const result = await apiHelper.listDistributers()
      setCity(result.data.data)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCity()
  }, [])

  const RegisterHandeler = async () => {
    try {
      const data = {
        ...userDetails,
        city: selectedCity
      }
      if (!data.fullName || data.fullName.length < 4) {
        setSnackbar({ open: true, message: "Full name must be at least 4 characters", severity: "error" });
        return;
      }
      if (!data.city) {
        setSnackbar({ open: true, message: "City is required", severity: "error" });
        return;
      }
      if (!data.phone) {
        setSnackbar({ open: true, message: "Phone number is required", severity: "error" });
        return;
      }
      if (!data.password) {
        setSnackbar({ open: true, message: "Password is required", severity: "error" });
        return;
      }
      setloading(true)
      const result = await apiHelper.registerUser(data)
      setloading(false)
      if (result.data.message === "Success") navigate(Path.login)
    } catch (error) {
      setloading(false)
      setMessage(error.message)
    }
  }



  useEffect(() => {
    if (Auth && Auth.role === 2) {
      navigate(Path.home);
    }

    // eslint-disable-next-line
  }, [Auth]);



  return <>
    <Loader loading={loading} />
    <center className="py-3">
      <div className="card login_card" style={{ width: "25rem" }}>
        <img src="./assets/DSC_0248-4-scaled.webp" className="card-img-top" alt="..." />
        <div className="card-body p-4">
          <h6 className="fw-bold">Sign Up to view your profile</h6>
          {
            Message && <div className="d-flex gap-2 align-items-center alert alert-danger" style={{ padding: "0.4rem 0.5rem" }}>
              <i className="fa-solid fa-circle-exclamation"></i>
              <small style={{ fontWeight: 500 }}>{Message}</small>
            </div>
          }
          <div className="py-1" >
            <input onChange={(e) => setuserDetails({ ...userDetails, fullName: e.target.value })} type="text" placeholder="FullName" />
            <br />
            <br />
            <input onChange={(e) => setuserDetails({ ...userDetails, phone: e.target.value })} type="text" placeholder="Phone Number" />
            <br />
            <br />
            <input onChange={(e) => setuserDetails({ ...userDetails, password: e.target.value })} type="password" placeholder="Password" />
            <br />
            <br />
            <select className="form-select" onChange={(e) => setselectedCity(e.target.value)}
              value={selectedCity}
              name="city"
              id="city">
              <option value="">Select a City</option>
              {
                City && City.map((item) => {
                  return <option key={item.city} value={item.city}>{item.city}</option>
                })
              }
            </select>
            <br />
            <button onClick={RegisterHandeler} className="btn text-light w-100 fw-bold py-2" style={{ backgroundColor: "orangered" }}>Sign Up</button>
            <center><small className="text-muted my-2 d-inline-block fw-bold">Allredy have an account</small></center>
            <button onClick={() => navigate(Path.login)} className="btn w-100 fw-bold" style={{ color: "orangered", textDecoration: "underline" }}>Sign In</button>
          </div>
        </div>
      </div>
    </center>

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  </>
}