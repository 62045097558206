import React, { useEffect, useRef, useState } from 'react'
import CategoryIcon from '@mui/icons-material/Category';
import { Link, useLocation } from 'react-router-dom';
import apiHelper from '../../Commen/ApiHelper';
import Path from '../../Commen/Path';

export default function ProductScreen() {
  const location = useLocation()
  const dropdownRef = useRef(null);
  const [categoryOpen, setcategoryOpen] = useState(false)

  function handleOpenCategory() {
    if (window.innerWidth < 992) {
      setcategoryOpen(!categoryOpen);
    }
  }

  useEffect(() => {
    setcategoryOpen(false)
  }, [location.pathname])

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setcategoryOpen(false);
      }
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setcategoryOpen(false);
      }
    }
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    }
  }, [])

  const QueryData = {
    All: "all",
    "5": "5rs",
    "10": "10rs",
    "20": "20rs",
    "50": "50Gm",
    "100": "100Gm",
    "250": "250g",
    "500": "500g",
    "1000": "1kg"
  }
  const [Products, setProducts] = useState([])
  const [Query, setQuery] = useState("all")

  async function getProduct(Query) {
    try {
      setQuery(Query)
      const result = await apiHelper.listProductByQuery(Query)
      setProducts(result.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProduct(QueryData.All)
  }, [])


  console.log(Products)

  return (
    <div className='ProductScreen'>
      <div className="">
        <div className="row gutter">
          <div className="col-12  d-none d-md-block">
            <div className="d-flex justify-content-center gap-3">
              <li onClick={() => getProduct(QueryData.All)} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData.All ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>All</li>
              <li onClick={() => getProduct(QueryData[5])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[5] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>₹ 5</li>
              <li onClick={() => getProduct(QueryData[10])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[10] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>₹ 10</li>
              <li onClick={() => getProduct(QueryData[20])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[20] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>₹ 20</li>
              <li onClick={() => getProduct(QueryData[50])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[50] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>50 g</li>
              <li onClick={() => getProduct(QueryData[100])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[100] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>100 g</li>
              <li onClick={() => getProduct(QueryData[250])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[250] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>250 g</li>
              <li onClick={() => getProduct(QueryData[500])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[500] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>500 g</li>
              <li onClick={() => getProduct(QueryData[1000])} className='btn btn-outline-dark border border-dark border-2' style={Query === QueryData[1000] ? { width: '80px', backgroundColor: "orangered", color: "white" } : { width: '80px' }}>1 kg</li>
            </div>
          </div>
          <div ref={dropdownRef} className="col-12 d-block d-md-none">
            <div onClick={handleOpenCategory} className="btn btn-outline-dark border border-dark border-2 w-100">
              <div className="d-flex justify-content-center gap-3">
                <span><CategoryIcon /></span>
                <span>Show all Category</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        categoryOpen ? <div className="col-12 shadow mt-3">
          <div className="d-flex flex-column align-items-center gap-3">
            <li onClick={() => getProduct(QueryData.All)} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>All</li>
            <li onClick={() => getProduct(QueryData[5])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>₹ 5</li>
            <li onClick={() => getProduct(QueryData[10])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>₹ 10</li>
            <li onClick={() => getProduct(QueryData[20])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>₹ 20</li>
            <li onClick={() => getProduct(QueryData[50])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>50 g</li>
            <li onClick={() => getProduct(QueryData[100])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>100 g</li>
            <li onClick={() => getProduct(QueryData[250])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>250 g</li>
            <li onClick={() => getProduct(QueryData[500])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>500 g</li>
            <li onClick={() => getProduct(QueryData[1000])} className='btn btn-outline-dark border border-dark border-2' style={{ width: '90%' }}>1 kg</li>
          </div>
        </div> : ""
      }

      <div className="section"></div>
      <div className="container ">
        <h4 className="fw-bold mb-3">
          Feture Products.
        </h4>
        <div className="row">
          {
            Products && Products?.map((item) => {
              return <Link to={Path.product.split(":")[0] + item._id} key={item._id} className="col-6 col-md-4 col-lg-3 mb-3 text-decoration-none text-dark">
                <div className="card">
                  <div>
                    <img src={item?.productImage?.url} alt="" width={"100%"} height={"200px"} />
                  </div>
                  <div className="card-body">
                    <span className="text-secondary mb-1 title d-block" style={{ maxWidth: "100%" }}>
                      {item.title}
                    </span>
                    <h4 className="fw-bold">₹ {item?.price} <small className="fw-500 text-secondary" style={{ fontSize: "14px" }}>onwards</small> </h4>
                    <small className="fw-bold text-secondary mb-2 d-block" style={{ fontSize: "14px" }}>Free Delivery</small>
                    <div className="d-flex gap-2 align-items-center">
                      <span style={{ fontSize: "1rem" }} className="badge d-flex align-items-center gap-1 rounded-pill bg-success">
                        <span>{"4.3"}</span>
                        <i className="fa-solid fa-star mt-1" style={{ fontSize: "10px" }}></i>
                      </span>
                      <small className="fw-bold text-secondary  d-block" style={{ fontSize: "13px" }}>{"1256"} Reviews</small>
                    </div>
                  </div>
                </div>
              </Link>
            })
          }
        </div>
      </div>
    </div>
  )
}
