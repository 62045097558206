import { useState } from "react";
import apiHelper from "../../Commen/ApiHelper";
import { useNavigate } from "react-router-dom";
import Path from "../../Commen/Path";
import Loader from "../../Components/Loader";
import { Alert, Snackbar } from "@mui/material";

export default function LoginScreen({ Auth, setAuth }) {
  const [Message, setMessage] = useState("");
  const [loading, setloading] = useState(false);
  const navigate = useNavigate()
  const [userDetails, setuserDetails] = useState({
    phone: '',
    password: ""
  });


  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });


  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };



  const LoginHandeler = async () => {
    try {
      setloading(true)
      if (!userDetails.phone) {
        setSnackbar({ open: true, message: "Phone number is required", severity: "error" });
        return;
      }
      if (!userDetails.password) {
        setSnackbar({ open: true, message: "Password is required", severity: "error" });
        return;
      }
      const result = await apiHelper.loginUser(userDetails)
      localStorage.setItem("token", result.data.token)
      setAuth(localStorage.getItem("token"))
      setloading(false)
      if (result.data.message === "Success") navigate(Path.home)
    } catch (error) {
      setloading(false)
      setMessage(error.message)
    }
  }


  return <>
    <Loader loading={loading} />
    <center className="py-3">
      <div className="card login_card shadow" style={{ width: "25rem" }}>
        <img src="./assets/DSC_0248-4-scaled.webp" className="card-img-top" alt="..." width={"100%"} />
        <div className="card-body p-4">
          <h6 className="fw-bold">Sign in to view your profile</h6>
          {
            Message && <div className="d-flex gap-2 align-items-center alert alert-danger" style={{ padding: "0.4rem 0.5rem" }}>
              <i className="fa-solid fa-circle-exclamation"></i>
              <small style={{ fontWeight: 500 }}>{Message}</small>
            </div>
          }
          <div className="py-1" >
            <input onChange={(e) => setuserDetails({ ...userDetails, phone: e.target.value })} type="text" placeholder="Phone Number" />
            <br />
            <br />
            <input onChange={(e) => setuserDetails({ ...userDetails, password: e.target.value })} type="password" placeholder="Password" />
            <br />
            <br />
            <button onClick={LoginHandeler} className="btn text-light w-100 fw-bold py-2" style={{ background: "orangered" }}>Sign In</button>
            <center><small className="text-muted my-2 d-inline-block fw-bold">Don't have an account </small></center>
            <button onClick={() => navigate(Path.register)} className="btn w-100 fw-bold" style={{ textDecoration: "underline", color: "orangered" }}>Sign Up</button>
          </div>
        </div>
      </div>
    </center>

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={handleSnackbarClose}
    >
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  </>
}