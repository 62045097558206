import { Link, useLocation, useNavigate } from "react-router-dom"
import Path from "../Commen/Path"
import { useEffect, useRef, useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import ShopIcon from '@mui/icons-material/Shop';

export default function Header({ setCartItems, CartTotalDetails, setAuth, userInfo }) {
    const location = useLocation()
    const navigate = useNavigate()
    const dropdownRef = useRef(null);
    const [DropOpen, setDropOpen] = useState(false)

    function handleOpenDropdawon() {
        if (window.innerWidth < 992) {
            setDropOpen(!DropOpen);
        }
    }

    useEffect(() => {
        setDropOpen(false)
    }, [location.pathname])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 992) {
                setDropOpen(false);
            }
        }
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropOpen(false);
            }
        }
        window.addEventListener("click", handleClickOutside);

        return () => {
            window.removeEventListener("click", handleClickOutside);
        }
    }, [])


    return <>
        <div ref={dropdownRef} className="container" style={location.pathname === Path.profile || location.pathname === Path.login || location.pathname === Path.register ? {display:"none"} :  {display:"block"}}>
            <header className="d-flex w-100 py-3 gap-4 align-items-center justify-content-between" >
                <div className="logo d-flex gap-2">
                    <div onClick={handleOpenDropdawon} className="d-flex d-block d-lg-none align-items-center">
                        <MenuIcon className="fs-1" style={{ color: "orangered" }} />
                    </div>
                    <Link to={Path.profile} style={{ textDecoration: "none" }} className="d-flex  align-items-center">
                        <h2 className="fw-bold fs-1" style={{ color: "orangered" }}>Mevada kalgi Tea</h2>
                    </Link>
                </div>
                <div className="d-flex d-none d-lg-block">
                    <ul className="m-0 p-0">
                        <li onClick={() => navigate(Path.home)} className="btn" style={{ fontSize: "20px" }}>Home</li>
                        <li onClick={() => navigate(Path.Product)} className="btn" style={{ fontSize: "20px" }}>Product</li>
                        <li onClick={() => navigate(Path.about)} className="btn" style={{ fontSize: "20px" }}>About</li>
                        <li onClick={() => navigate(Path.contact)} className="btn" style={{ fontSize: "20px" }}>Contact</li>
                    </ul>
                </div>
                <ul className="d-flex align-items-center gap-4  p-0 nav_items">
                    <li className="px-2 py-1 border-0">
                        <div className="icons d-flex gap-4 align-items-center justify-content-center">
                            <div style={{ position: 'relative', zIndex: "2", top: 0 }} className="d-flex flex-column align-items-center icon_hover">
                                <i className="fa-solid fa-user" style={{ fontSize: "1.2rem", padding: '1rem' }}></i>
                                <span style={{ fontSize: "1.1rem", cursor: "pointer" }} >{!userInfo ? "Profile" : userInfo.firstName}</span>
                                <ul style={{ listStyle: "none", background: "white", position: "absolute", marginTop: "3rem", zIndex: 1, width: "250px", padding: "1rem" }} className="shadow-lg hover_box">
                                    <li className="border-0">
                                        <strong style={{ textDecoration: "underline", fontWeight: 900, color: "orangered" }}>Hello {userInfo ? userInfo.firstName : "User"}</strong><br />
                                        <small className="text-muted">To access your Meesho Profile</small>
                                    </li>
                                    <li className="border-0">
                                        {
                                            userInfo ? <span className="btn w-100 mb-2 btn_profile">{"Profile"}</span> : ""
                                        }
                                        {
                                            userInfo ? <button onClick={() => {
                                                localStorage.removeItem("token")
                                                setAuth(localStorage.getItem("token"))
                                                setCartItems([])
                                                if (location.pathname === Path.home) {
                                                    navigate(Path.login)
                                                } else {
                                                    navigate(Path.home)
                                                }

                                            }} className="w-100 rounded-2" style={{ paddingTop: "0.3rem", paddingBottom: "0.3rem", fontWeight: 700, color: "orangered", background: "white", border: "1px solid orangered" }}>Sign Out</button> : <button className="btn w-100" style={{ background: "orangered", color: "white" }} onClick={() => navigate(Path.login)}>Sign In</button>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div style={{ position: "relative", top: 0 }} className="d-flex flex-column icon_hover align-items-center cart_link">
                                <i className="fa-solid fa-basket-shopping" style={{ fontSize: "1.2rem" }}></i>
                                <span style={{ fontSize: "1.1rem" }}>Cart</span>
                                <span className="btn-primary" style={{ fontSize: "14px", padding: "0px 5px", borderRadius: "50%", position: "absolute", top: -10, right: -8 }}>{CartTotalDetails.totalItems}</span>
                                <ul style={{ listStyle: "none", background: "white", position: "absolute", marginTop: "2.7rem", zIndex: 1, width: "250px", padding: "1rem" }} className="shadow-lg hover_box">
                                    <li className="border-0">
                                        <strong style={{ textDecoration: "underline", fontWeight: 900 }}>Your Cart Details</strong>
                                    </li>
                                    <li className="border-0 text-dark">
                                        {userInfo && <>
                                            <div className="d-flex pt-2 align-items-center justify-content-between">
                                                <span>Total Products</span>
                                                <span>+ {CartTotalDetails.totalItems}</span>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <span>Total Order</span>
                                                <span>+ ₹ {CartTotalDetails.totalPrice}</span>
                                            </div>
                                            <br />
                                        </>
                                        }
                                        <small className="text-muted">To access your Cart</small>
                                        {
                                            userInfo ? <button onClick={() => {
                                                navigate(Path.cart)
                                            }} className="w-100 btn-primary btn"><i className="fa-brands fa-opencart"> &nbsp;</i> Go To Cart</button> : <button className="btn btn-primary w-100" onClick={() => {
                                                navigate(Path.login)
                                            }}>Sign In</button>
                                        }
                                    </li>
                                </ul>
                            </div>
                            <div onClick={() => navigate(Path.orderDetails)} className="btn icon_hover d-flex flex-column justify-content-center align-items-center">
                                <ShopIcon />
                                <span>Order</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </header>

        </div>
        <hr className={location.pathname === Path.home ? "m-0" : "m-1"} style={location.pathname === Path.profile ? {display:"none"} :  {display:"block"}} />

        {
            DropOpen ? <div className="d-flex d-block d-lg-none justify-content-center">
                <ul className="m-0 p-0">
                    <li onClick={() => navigate(Path.home)} className="btn" style={{ fontSize: "20px" }}>Home</li>
                    <li onClick={() => navigate(Path.Product)} className="btn" style={{ fontSize: "20px" }}>Product</li>
                    <li onClick={() => navigate(Path.about)} className="btn" style={{ fontSize: "20px" }}>About</li>
                    <li onClick={() => navigate(Path.contact)} className="btn" style={{ fontSize: "20px" }}>Contact</li>
                </ul>
            </div> : ""
        }
    </>
}