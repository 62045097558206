import LocalCafeIcon from '@mui/icons-material/LocalCafe';
export default function Loader({ loading }) {
    if (loading) return <>
        <div className='loader flex-column'>
           <LocalCafeIcon  className="rotating-icon" style={{color:"orangered", fontSize:"100px"}}/>
        </div>
    </>

    return <>
        <div className='loader flex-column' style={{height:0}}>
        </div>
    </>
}