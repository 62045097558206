import React from 'react'

export default function ContactScreen() {
    return (
        <div className='ContactScreen'>
            <div className="Contact">
                <div className="Contact_img">
                    <div className="back"></div>
                    <div className="Contact_content">
                        <div className="h1">Contact Us</div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-12 col-lg-6">
                        <div className="d-flex justify-content-center">
                            <div className="Form">
                                <div className="h5">Communicate with us</div>
                                <label htmlFor="name">Name : </label>
                                <input className='form-control' type="text" placeholder='Enter your full name'/><br />
                                <label htmlFor="name">Email : </label>
                                <input className='form-control' type="email"  placeholder='Enter your email address'/><br />
                                <label htmlFor="name">Message : </label>
                                <textarea className='form-control' type="text" placeholder='Type your message'/><br />
                                <div className="d-flex justify-content-center">
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                    <div className="d-flex justify-content-center">
                        <div className="contact_content">
                            <div className='text-center mb-2'>
                                <img src="./assets/Logo.png" alt="" width={"150px"}/>
                            </div>
                            <div className="h5">Address : </div>
                            <p>Krishna Nagar 318 Dabhoi Kapurai Road Kotambi Vadodara Gujarat, 390004</p>
                            <div className="h5">Email : </div>
                            <p>kalgitea@gmail.com</p>
                            <div className="h5">Phone Number : </div>
                            <p>+91 84609 99006</p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
        </div>
    )
}
