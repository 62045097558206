import React from 'react'
import { useNavigate } from 'react-router-dom'
import Path from '../../Commen/Path'

export default function ProfileScreen({ userInfo, setAuth, setuserInfo }) {
  const navigate = useNavigate()
  return (
    <div className="ProfileScreen">
      <div className="Profile_img">
        <div className="Profile_content mt-4">
          <h2 className="fw-bold fs-1 text-center" style={{ color: "orangered" }}>
            Mevada Kalgi Tea
          </h2>
          <div className="h2 my-4 text-center">My Profile Information:</div>
          <p className="h4 my-4">Name: {userInfo.fullName}</p>
          <p className="h4 my-4">Phone: {userInfo.phone}</p>
          <p className="h4 my-4">City: {userInfo.city}</p>
          <div className="section"></div>
          <div className="Note" style={{ color: "white" }}>
            <div className="h5">Dear {userInfo.fullName},</div>
            <p style={{ textIndent: "40px", textAlign: "justify" }}>
              I wanted to take a moment to express my heartfelt gratitude for joining our company and for the exceptional work you’ve done since day one. Your dedication, expertise, and positive attitude have made a significant impact, and I truly appreciate your contributions.
            </p>
            <p style={{ textIndent: "40px", textAlign: "justify" }}>
              Thank you for your support and for being such a valuable part of the team. I’m excited about what we can accomplish together in the future and am grateful to have you on board.
            </p>
            <div className="h5 text-end">Warm regards,</div>
            <div className="h4 text-end">Kalgi Tea Management</div>
          </div>
        </div>
        <div className="front btn d-block d-md-none">
          <div className="text-center">
            <button onClick={() => {
              localStorage.removeItem("token")
              setAuth("")
              setuserInfo({})
              navigate(Path.login)
            }} className='btn btn-primary w-50'>Logout</button>
          </div>
        </div>
        <div onClick={() => navigate(Path.home)} className="front btn d-none d-md-block">
          Return Home
        </div>
        <div className="section d-block d-md-none"></div>
      </div>
    </div>

  )
}
