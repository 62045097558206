import React from 'react'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import GppGoodIcon from '@mui/icons-material/GppGood';


export default function AboutScreen() {
    return (
        <div className='AboutScreen'>
            <div className="About">
                <div className="About_img">
                    <div className="back"></div>
                    <div className="About_content">
                        <div className="h1">About Us</div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="h4 fw-bold">Our Story</div>
                        <p style={{ textAlign: "justify" }}>
                            2002 by Kalgi And N.K.Mevada is a collection of specially curated tea blends poised to spark many moments of magic. Inspired by India – this collection tells the glorious stories of tea in a simple yet evocative manner. Each tea is born amidst a variety of flora, fauna, climate and topography. Furthermore, teas are paired with spices and flavours, fruits & flowers culminating in a taste experience that is unforgettable. 1868 is a celebration of diversity with each carefully selected variant having its unique story to tell, 1868 is celebration of Truly Indian Tea Stories.
                        </p>
                    </div>
                    <div className="col-12 col-lg-5 my-3">
                        <div className="h4 fw-bold">Our Roots</div>
                        <p style={{ textAlign: "justify" }}>
                            Around the same time, in 2002s, we had our humble beginnings in the tea business. We truly believed that tea with all its inherent benefits could change the world.
                        </p>
                    </div>
                    <div className="col-12 col-lg-5 my-3">
                        <div className="h4 fw-bold">Our Vision</div>
                        <p style={{ textAlign: "justify" }}>
                            With our founder’s dedication and master blender’s passion to bring premium quality Indian teas to the world, today Tea India is available in most Grocery Stores across Ahmedabad, Surat, Baroda etc. And now with the growing popularity of Chai, we’re growing our presence in other mainstream grocery stores across Gujrat.
                        </p>
                    </div>
                    <div className="col-12 col-lg-6 my-3">
                        <div className="text-center rounded-5">
                            <img className='rounded-3' src="./assets/cup-with-tea-mint.webp" alt="" width={"320px"} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 my-3 d-flex  align-items-center">
                        <div className="">
                            <div className="h4 fw-bold">The Company</div>
                            <p style={{ textAlign: "justify" }}>
                                We couldn’t have come so far without the blessings of Mother Nature and the support of our farmers. So we strive hard to hold ourselves to the highest social and environmental standards.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="section"></div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                <LocalShippingIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                Fast And Free Delivery
                            </div>
                            <p>
                                Free delivery for all orders over ₹ 1000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                <HeadsetMicIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                24/7 Customer Support
                            </div>
                            <p>
                                Friendly 24/7 customer support
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                < GppGoodIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                Money Back Guarantee
                            </div>
                            <p>
                                We return money within 30 days
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
            </div>
        </div>
    )
}
