import axios from "axios"
import Path from "./Path"

class ApiHelper {
  constructor() {
    // this.baseUrl = "http://localhost:5000"
    // this.baseUrl = "http://192.168.29.32:5000"
    // this.baseUrl = "http://192.168.1.4:8050"
    this.baseUrl = "https://api.mevadakalgitea.scriptscholer.in"


    axios.interceptors.request.use((config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`; // Use Authorization header
      }
      config.headers["token"] = localStorage.getItem("token")
      return config
    }, (err) => {
      return Promise.reject(err)
    })

    axios.interceptors.response.use((res) => {
      return res
    }, (err) => {

      if (err && err.res && err.res.status === 401 && err.res.data.message === "unAuthorized") {
        localStorage.removeItem("token")
        window.location.pathname = Path.login
        return
      }
      return Promise.reject(err)
    })

  }

  listProduct() {
    return axios.get(this.baseUrl + "/product/list")
  }

  GetProductById(id) {
    return axios.get(this.baseUrl + "/product/details/" + id)
  }
  listProductByQuery(query) {
    return axios.get(this.baseUrl + "/product/list/" + query)
  }
  loginUser(data) {
    return axios.post(this.baseUrl + "/user/login", data)
  }
  registerUser(data) {
    return axios.post(this.baseUrl + "/user/create", data)
  }
  listDistributers() {
    return axios.get(this.baseUrl + "/user/distributers/")
  }
  addtoCart(data) {
    return axios.post(this.baseUrl + "/cart/create", data)
  }
  listCart(user) {
    return axios.get(this.baseUrl + "/cart/" + user)
  }
  removeToCart(cartId) {
    return axios.delete(this.baseUrl + "/cart/delete/" + cartId)
  }
  addAddress(data) {
    return axios.post(this.baseUrl + '/address/add', data)
  }
  listAddress(userId) {
    return axios.get(this.baseUrl + "/address/list/" + userId)
  }
  getAddressById(id) {
    return axios.get(this.baseUrl + "/address/" + id)
  }
  createOrder(data) {
    return axios.post(this.baseUrl + "/order/create", data)
  }
  listOrderByUser(id) {
    return axios.get(`${this.baseUrl}/order/list/user/${id}`)
  }
  recieveOrder(data) {
    return axios.put(`${this.baseUrl}/order/recieve/update`, data)
  }
  EmptyUserCart(userId) {
    return axios.delete(this.baseUrl + "/cart/empty/" + userId)
  }
}

const apiHelper = new ApiHelper()
export default apiHelper