import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import apiHelper from "../../Commen/ApiHelper";
import Loader from "../../Components/Loader";
import Path from "../../Commen/Path";
import Footer from "../HomeScreen/Footer";

export default function OrderDetailsScreen({ userInfo }) {
    const [OrderDetails, setOrderDetails] = useState([]);
    const [loading, setloading] = useState(false);
    const [OrderTotal, setOrderTotal] = useState({
        totalItems: 0,
        totalPrice: 0,
    });

    console.log(OrderDetails)

    async function FetchOrderDetails() {
        try {
            setloading(true)
            const reuslt = await apiHelper.listOrderByUser(userInfo?._id)
            const products = reuslt.data.data
            setOrderDetails(reuslt.data.data)
            let totalItems = 0
            let totalPrice = 0
            for (let i = 0; i < products.length; i++) {
                let product = products[i]
                if (product.paymentStatus === 0) {
                    totalItems += product.qty
                    totalPrice += product.totalAmount
                }
            }

            setOrderTotal({
                totalItems: totalItems,
                totalPrice: totalPrice,
            })
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        FetchOrderDetails()
        // eslint-disable-next-line
    }, []);


    async function recieveOrder(id) {
        try {
            const data = {
                orderId:id,
                deliveryStatus:1
            }
            await apiHelper.recieveOrder(data)
            FetchOrderDetails()
        } catch (error) {
            console.log(error)
        }
    }


    return <>
        <Loader loading={loading} />
        <div className="cart_container container">
            <div className="row">
                <div className="col-12 col-md-7 p-0">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Your Order Product : </h5>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-7 mb-2 ps-md-0 p-2" >
                    {
                        OrderDetails && OrderDetails?.map((x) => {
                            return <div key={x._id} className="card cart_card mb-2 w-100" style={{ background: "#e7eeff", border: "1px solid #e7eeff" }}>
                                <div className="card-body">
                                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-center gap-2">
                                        <div>
                                            <Link to={Path.product.split(":")[0] + x?.productId?._id}>
                                                <img className="cart_item_img w-sm-100" src={x?.productId?.productImage?.url} alt={x?.productId?.title} />
                                            </Link>
                                        </div>
                                        <div style={{ width: "calc(100% - 100px)" }}>
                                            <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                                                <h6 className="title" style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                                    <Link style={{ color: "inherit", textDecoration: "none" }} to={Path.product.split(":")[0] + x._id}>
                                                        {x.productId?.title}
                                                    </Link>
                                                </h6>
                                            </div>
                                            <span className="fs-6" style={{ fontWeight: "300" }}>
                                                ₹ {

                                                    x?.productId?.price
                                                }
                                            </span>
                                            <br />
                                            <span className="fs-6" style={{ fontWeight: "300" }}>
                                                All issue easy returns allowed
                                            </span>
                                            <br />
                                            <span className="fs-6 mt-2" style={{ fontWeight: "300" }}>
                                                Qty: &nbsp; {x.qty}
                                            </span><br />


                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <span className="fs-6 mt-2" style={{ fontWeight: "300" }}>
                                            Payment Status : &nbsp; <span className={x.paymentStatus === 1 ? "btn btn-success" : "btn btn-warning"}>{x.paymentStatus === 1 ? "Completed" : "Pending"}</span>
                                        </span>
                                        <span className="fs-6 mt-2" style={{ fontWeight: "300" }}>
                                            Delivery Status : &nbsp; <span className={x.deliveryStatus === 1 ? "btn btn-success" : "btn btn-warning"}>{x.deliveryStatus === 1 ? "Completed" : "Pending"}</span>
                                        </span>
                                        <span className="fs-6 mt-2" style={{ fontWeight: "300" }}>
                                            Order Status : &nbsp; <span className={x.orderStatus === 2 ?  "btn btn-success" : x.orderStatus === 1 ? "btn btn-dark" : "btn btn-warning"}>{x.orderStatus === 2 ? "Dispatch" : x.orderStatus === 1 ? "Accept" : "Pending"}</span>
                                        </span>
                                        {
                                           x?.deliveryStatus !== 1 ?  x?.orderStatus === 2 && x?.deliveryStatus === 0 ? <span className="mt-2">
                                           <button onClick={() => recieveOrder(x?._id)} className="btn btn-primary ">
                                               Recieve Order
                                           </button>
                                       </span> :
                                           "" : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
                <div className="col-12 col-lg-5 mb-2 p-0 my-2">
                    <div className="card mb-2 w-100" style={{ background: "#e7eeff", border: "1px solid #e7eeff" }}>
                        <div className="card-body">
                            <h5>Pending Product Details</h5>
                            <div className="d-flex w-100 justify-content-between mt-3">
                                <h6>Total Items</h6>
                                <h6>{OrderTotal.totalItems}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Product Price</h6>
                                <h6>+ {OrderTotal.totalPrice}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h5>Order Total</h5>
                                <h5>+ {OrderTotal.totalPrice}</h5>
                            </div>
                        </div>
                    </div>
                    <img src="https://img.freepik.com/premium-vector/contactless-safe-delivery-service-flat-style-vector-template-banner-landing-page-web-site-white-background_546563-150.jpg" alt="img" width={"100%"} />
                </div>
            </div>
        </div>
    </>
}