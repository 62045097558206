import {  useState } from "react";
import Loader from "../../Components/Loader";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import GppGoodIcon from '@mui/icons-material/GppGood';
import ProductScreen from "../ExtraScreens/ProductScreen";

export default function HomeScreen() {
    const [loading, setloading] = useState(false);

    return <>
        <Loader loading={loading} />
        <div className="Home">
            <div className="Home_img">
                <div className="back"></div>
                <div className="home_content">
                    <div className="h2">New Season Arrivals</div>
                    <p>This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
                </div>
            </div>
        </div>
        <div className="section"></div>
            <ProductScreen />
        <div className="container">
            <div className="section"></div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="Part Part_img">
                        <div className="back"></div>
                        <div className="Part_content">
                            <div className="h4">Assam Garden Refresh Tea</div>
                            <p className="btn text-light">Shop Now</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="Part Part_img2">
                        <div className="back"></div>
                        <div className="Part_content">
                            <div className="h4">Tested by Tea Experts</div>
                            <p className="btn text-light">Shop Now</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                <LocalShippingIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                Fast And Free Delivery
                            </div>
                            <p>
                                Free delivery for all orders over ₹ 1000
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                <HeadsetMicIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                24/7 Customer Support
                            </div>
                            <p>
                                Friendly 24/7 customer support
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="d-flex border shadow p-4 justify-content-center align-items-center">
                        <div className="block">
                            <div className="text-center my-2">
                                < GppGoodIcon className="fs-1"/>
                            </div>
                            <div className="h4 my-3">
                                Money Back Guarantee
                            </div>
                            <p>
                                We return money within 30 days
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section"></div>
        </div>
    </>
}