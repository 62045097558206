const Path = {
    login:"/",
    home:"/home",
    about:"/about",
    profile:"/profile",
    Product:"/productscreen",
    contact:"/contact",
    product:'/product/:id',
    register:'/register',
    cart:"/cart",
    addresss:'/address',
    payment:'/payment',
    checkout:"/checkout/:id",
    orderDetails:"/user/order",
    orderList:"/order"
}

export default Path