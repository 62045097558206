import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import Path from "../../Commen/Path"
import apiHelper from "../../Commen/ApiHelper";
import Loader from "../../Components/Loader";

export default function CheckoutScreen({ userInfo, Auth, FetchUserCart, CartItems, setCartItems, CartTotalDetails }) {
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setloading] = useState(false);
    const [Address, setAddress] = useState({});
    const [Product, setProduct] = useState({});

    useEffect(() => {
        if (!Auth) {
            navigate(Path.login)
        }
        // eslint-disable-next-line
    }, [Auth]);


    async function GetAddress() {
        try {
            setloading(true)
            const result = await apiHelper.getAddressById(id)
            setAddress(result.data.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }



    useEffect(() => {
        if (id) {
            GetAddress()
        }
        // eslint-disable-next-line
    }, []);



    async function PlaceOrder() {
        try {
            if (!userInfo || !userInfo._id) {
                navigate(Path.login)
                return
            }
            const data = {
                orderedFor: userInfo?.adminDetails?._id,
                orderedBy: userInfo?._id,
                products: CartItems,
                address:Address,
                lastActionedBy:userInfo._id,
                totalAmount:CartTotalDetails?.totalPrice
            }
            console.log(data)
            setloading(true)
            const result = await apiHelper.createOrder(data)
            if (result.data.message === "Success") {
                await apiHelper.EmptyUserCart(userInfo?._id)
                setCartItems()
                navigate(Path.home)
            }
            setloading(false)
        } catch (error) {
            console.log(error)
            setloading(false)
        }
    }

    const qtyHendeler = (qty, index) => {
        if (Product._id) {
            setProduct({ ...Product, qty: qty })
            return
        }
        const tmp = [...CartItems]
        tmp[index].qty = qty
        setCartItems([...tmp])
    }


    return <>
        <Loader loading={loading} />
        <Loader loading={loading} />
        <div className="container cart_container">
            <div className="row">
                <div className="col-12 col-md-7 p-0">
                    <div className="d-flex justify-content-between align-items-center px-2 px-md-0">
                        <h5 style={{ width: "100%", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>Check Confirm Your Order</h5>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-7 mb-2 ps-md-0" >
                    {
                        CartItems && CartItems.map((x, key) => {
                            return <div key={x.productId?._id} className="card cart_card mb-2 w-100">
                                <div className="card-body">
                                    <div className="d-flex flex wrap gap-2">
                                        <div>
                                            <Link to={Path.product.split(":")[0] + x.productId?._id}>
                                                <img className="cart_item_img2 w-sm-100" src={x.productId.productImage?.url} alt={x.productId?.title} />
                                            </Link>
                                        </div>
                                        <div style={{ width: "calc(100% - 100px)" }}>
                                            <div className="d-flex gap-2 justify-content-between w-100 align-items-center">
                                                <h6 className="title" style={{ fontWeight: "500", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>
                                                    <Link style={{ color: "inherit", textDecoration: "none" }} to={Path.product.split(":")[0] + x.productId?._id}>
                                                        {x.productId.title}
                                                    </Link>
                                                </h6>
                                            </div>
                                            <span className="fs-6" style={{ fontWeight: "300" }}>
                                                ₹ {x.productId?.price}
                                            </span>
                                            <br />
                                            <span className="fs-6" style={{ fontWeight: "300" }}>
                                                All issue easy returns allowed
                                            </span>
                                            <br />
                                            <span className="fs-6 d-inline-block mt-2" style={{ fontWeight: "300", border: "2px solid #f1f5fe" }}>
                                                <button onClick={() => qtyHendeler(x.qty - 1, key)} disabled={x.qty <= 1} className="btn" style={{ background: "#f1f5fe", fontSize: "14px", border: "none", padding: "2px 1rem" }}><i className="fa-solid fa-minus"></i></button>
                                                &nbsp; &nbsp;{x.qty}&nbsp; &nbsp;
                                                <button onClick={() => qtyHendeler(x.qty + 1, key)} disabled={x.qty >= 20} className="btn" style={{ background: "#f1f5fe", fontSize: "14px", border: "none", padding: "2px 1rem" }}><i className="fa-solid fa-plus"></i></button>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                    <label className="card p-3 d-block mb-2 w-100" style={{ cursor: "pointer", background: "rgba(255, 68, 0, 0.2)", border: "1px solid #e7eeff" }}>
                        <div className="card_body">
                            <div className="d-flex align-items-start gap-3">

                                <div style={{ width: " 100%" }}>
                                    <h5 className="fw-bold mb-0">{Address.fullName}</h5>
                                    <p className="my-2 fw-normal w-100 text-muted">
                                        {Address.address + "," + Address.city} <br />
                                        {Address.state} - {Address.pincode} <br />
                                        {Address.phone}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </label>
                   

                </div>

                <div className="col-12 col-md-5 mb-2 pe-md-0">
                    <div className="card w-100">
                        <div className="card-body">
                            <h5>Price Details</h5>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Items</h6>
                                <h6>+ {CartTotalDetails.totalItems}</h6>
                            </div>
                            <div className="d-flex w-100 justify-content-between">
                                <h6>Total Product Price</h6>
                                <h6>+ ₹ {CartTotalDetails.totalPrice}</h6>
                            </div>
                            <hr />
                            <div className="d-flex w-100 justify-content-between">
                                <h5>Order Total</h5>
                                <h5>+ ₹ {CartTotalDetails.totalPrice}</h5>
                            </div>
                            <small style={{ fontSize: "12px", display: "block" }} className="my-2 text-muted text-center">Clicking on ‘Continue’ will not deduct any money</small>
                            <button onClick={() => PlaceOrder()} className="btn btn-primary w-100 py-2" >Place Order</button>
                            <img src="https://images.meesho.com/images/marketing/1588578650850.webp" alt="img" width={"100%"} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </>
}